import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  CardActions,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Link from 'next/link';

const TestLeadPromo: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isOctober2024 = (): boolean => {
    const now = new Date();
    return now.getFullYear() === 2024 && now.getMonth() === 9; 
  };

  const checkIfDismissed = (): boolean => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('promoDismissed') === 'true';
    }
    return false;
  };

  useEffect(() => {
    if (isOctober2024() && !checkIfDismissed()) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    if (typeof window !== 'undefined') {
      localStorage.setItem('promoDismissed', 'true');
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Card
      sx={{
        marginBottom: 8,
        position: 'relative',
        backgroundColor: '#1C2536',
        color: '#000',
        maxWidth: '850px',
      }}
    >
      <CardActions sx={{ marginTop: 1 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            gutterBottom
            marginLeft={2}
            variant="h5"
            component="div"
            color={'#fff'}
          >
            Ny mulighed for at sælge de lagerbiler der hænger
          </Typography>
          <IconButton onClick={handleClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </CardActions>
      <CardContent sx={{ marginTop: 0, paddingTop: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box marginRight={4}>
            <Typography variant="body2" color={'#fff'} component="p">
              Vi introducerer <strong>FavoritLead</strong> - et nyt produkt der kan hjælpe dig
              med at rydde op i dit varelager, uden at du behøver sætte bilerne
              mange tusinde kroner ned for at øge aktiviteten.
            </Typography>
            <Typography
              variant="body2"
              color={'#fff'}
              component="p"
              marginTop={2}
            >
              Med FavoritLead kan du kontakte de brugere, der har gemt dine
              biler som favorit på Bilhandel! Det er en helt unik mulighed for
              proaktivt at gøre en ekstra indsats for at få solgt de af dine
              biler som ikke flytter sig.
            </Typography>
            <Typography
              variant="body2"
              color={'#fff'}
              component="p"
              marginTop={2}
            >
              Det fungerer ved, at du <Link href={'/dashboard/classifieds?only-favorites=true'}><Typography variant="body2" color={'#fff'} component={'span'} fontWeight={'bold'} sx={{textDecoration: 'underline'}}>her i dit Dashboard</Typography></Link> kan se hvilke biler
              der er gemt som favorit af brugere. Med et tryk på en knap kan du
              se deres kontaktoplysninger og tage kontakt. Brugerne bliver
              spurgt på Bilhandel, når de gemmer en bil, om de ønsker at blive
              kontaktet af forhandleren, hvis denne har et godt tilbud på bilen.
              Du får desuden en email, når en af dine biler gemmes som favorit.
            </Typography>
            <Typography
              variant="body2"
              color={'#fff'}
              component="p"
              marginTop={2}
            >
              FavoritLead koster kr. 300,- pr stk, og inden du køber et lead,
              kan du se om brugeren har opgivet navn, telefonnummer og email.
            </Typography>
          </Box>
          <img src="/assets/iconly/favoritter2.svg" height={100}  />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TestLeadPromo;
